import { useHttpClient } from "@/hooks/use-http-client";
import { AthleteControllerApi, AthleteSummary } from "@/api/generated";
import { SortablePageRequestParams } from "@/types/page";
import { AthleteSearchParams } from "@/types/athlete";

export const useAthlete = () => {
  const httpClient = useHttpClient();

  const controller = new AthleteControllerApi(undefined, undefined, httpClient);

  const athleteMe = async () => {
    try {
      return (await controller.athleteMe()).data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAthletes = async (
    params: SortablePageRequestParams<AthleteSearchParams>,
  ): Promise<AthleteSummary[]> => {
    try {
      const {
        page,
        size,
        sort,
        searchParams: {
          name,
          location: { longitude, latitude } = { longitude: 0, latitude: 0 },
          sportId,
        },
      } = params;
      const response = await controller.searchAthletes(
        latitude,
        longitude,
        page,
        size,
        sort,
        sportId,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return {
    athleteMe,
    getAthletes,
  };
};
