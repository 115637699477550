/* tslint:disable */
/* eslint-disable */
/**
 * Let\'s Sport DEV API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
    /**
     * 
     * @type {number}
     * @memberof AccountInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInfo
     */
    'emailConfirmed': boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    'role': AccountInfoRoleEnum;
}

export const AccountInfoRoleEnum = {
    Athlete: 'ATHLETE',
    Organization: 'ORGANIZATION',
    Coach: 'COACH',
    Admin: 'ADMIN'
} as const;

export type AccountInfoRoleEnum = typeof AccountInfoRoleEnum[keyof typeof AccountInfoRoleEnum];

/**
 * 
 * @export
 * @interface Athlete
 */
export interface Athlete {
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'lastName': string;
    /**
     * 
     * @type {Location}
     * @memberof Athlete
     */
    'location': Location;
    /**
     * 
     * @type {Array<string>}
     * @memberof Athlete
     */
    'sports': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Athlete
     */
    'isCoach': boolean;
}
/**
 * 
 * @export
 * @interface AthleteSummary
 */
export interface AthleteSummary {
    /**
     * 
     * @type {number}
     * @memberof AthleteSummary
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AthleteSummary
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteSummary
     */
    'lastName': string;
    /**
     * 
     * @type {Location}
     * @memberof AthleteSummary
     */
    'location': Location;
    /**
     * 
     * @type {Array<string>}
     * @memberof AthleteSummary
     */
    'sports': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AthleteSummary
     */
    'isCoach': boolean;
}
/**
 * 
 * @export
 * @interface CreateAthleteRequest
 */
export interface CreateAthleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'lastName': string;
    /**
     * 
     * @type {Location}
     * @memberof CreateAthleteRequest
     */
    'location': Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateAthleteRequest
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAthleteRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAthleteRequest
     */
    'isCoach'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateEventRequest
 */
export interface CreateOrUpdateEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'sport': string;
    /**
     * 
     * @type {Location}
     * @memberof CreateOrUpdateEventRequest
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateEventRequest
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateEventRequest
     */
    'isPublic': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateEventRequest
     */
    'socialMedia'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEventRequest
     */
    'training'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateExternalEventRequest
 */
export interface CreateOrUpdateExternalEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'sport': string;
    /**
     * 
     * @type {Location}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'avatar'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'isPublic': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'socialMedia'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'training'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'externalEventUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateExternalEventRequest
     */
    'externalRegistrationUrl': string;
}
/**
 * 
 * @export
 * @interface CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof CreateOrganizationRequest
     */
    'location': Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateOrganizationRequest
     */
    'sportIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface EmailExistsRequest
 */
export interface EmailExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailExistsRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'creatorId': number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof Event
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'sport': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    'isPublic': boolean;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'externalEventUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'externalRegistrationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'moderationStatus': EventModerationStatusEnum;
}

export const EventModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type EventModerationStatusEnum = typeof EventModerationStatusEnum[keyof typeof EventModerationStatusEnum];

/**
 * 
 * @export
 * @interface EventDto
 */
export interface EventDto {
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'creatorId': number;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof EventDto
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'sport': string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDto
     */
    'distance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EventDto
     */
    'isPublic': boolean;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'externalEventUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'externalRegistrationUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDto
     */
    'moderationStatus': EventDtoModerationStatusEnum;
}

export const EventDtoModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;

export type EventDtoModerationStatusEnum = typeof EventDtoModerationStatusEnum[keyof typeof EventDtoModerationStatusEnum];

/**
 * 
 * @export
 * @interface EventSummary
 */
export interface EventSummary {
    /**
     * 
     * @type {number}
     * @memberof EventSummary
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EventSummary
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof EventSummary
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof EventSummary
     */
    'sport': string;
    /**
     * 
     * @type {string}
     * @memberof EventSummary
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {Location}
     * @memberof Organization
     */
    'location': Location;
    /**
     * 
     * @type {Array<string>}
     * @memberof Organization
     */
    'sports': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface Sport
 */
export interface Sport {
    /**
     * 
     * @type {number}
     * @memberof Sport
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Sport
     */
    'name': string;
}

/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailExistsRequest} emailExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsByEmail: async (emailExistsRequest: EmailExistsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailExistsRequest' is not null or undefined
            assertParamExists('existsByEmail', 'emailExistsRequest', emailExistsRequest)
            const localVarPath = `/api/account/email/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailExistsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/api/account/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAthleteRequest} createAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAthlete: async (createAthleteRequest: CreateAthleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAthleteRequest' is not null or undefined
            assertParamExists('registerAthlete', 'createAthleteRequest', createAthleteRequest)
            const localVarPath = `/api/account/register/athlete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAthleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerOrganization: async (createOrganizationRequest: CreateOrganizationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationRequest' is not null or undefined
            assertParamExists('registerOrganization', 'createOrganizationRequest', createOrganizationRequest)
            const localVarPath = `/api/account/register/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.accountMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmailExistsRequest} emailExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existsByEmail(emailExistsRequest: EmailExistsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existsByEmail(emailExistsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.existsByEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAthleteRequest} createAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAthlete(createAthleteRequest: CreateAthleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAthlete(createAthleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.registerAthlete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerOrganization(createOrganizationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.registerOrganization']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountMe(options?: any): AxiosPromise<AccountInfo> {
            return localVarFp.accountMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailExistsRequest} emailExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existsByEmail(emailExistsRequest: EmailExistsRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.existsByEmail(emailExistsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAthleteRequest} createAthleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAthlete(createAthleteRequest: CreateAthleteRequest, options?: any): AxiosPromise<Athlete> {
            return localVarFp.registerAthlete(createAthleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrganizationRequest} createOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: any): AxiosPromise<Organization> {
            return localVarFp.registerOrganization(createOrganizationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountMe(options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).accountMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailExistsRequest} emailExistsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public existsByEmail(emailExistsRequest: EmailExistsRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).existsByEmail(emailExistsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public login(loginRequest: LoginRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAthleteRequest} createAthleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public registerAthlete(createAthleteRequest: CreateAthleteRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).registerAthlete(createAthleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrganizationRequest} createOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public registerOrganization(createOrganizationRequest: CreateOrganizationRequest, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).registerOrganization(createOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AthleteControllerApi - axios parameter creator
 * @export
 */
export const AthleteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        athleteMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/athlete/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAthletes: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/athlete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationLatitude 
         * @param {number} locationLongitude 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [sportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAthletes: async (locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, sportId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationLatitude' is not null or undefined
            assertParamExists('searchAthletes', 'locationLatitude', locationLatitude)
            // verify required parameter 'locationLongitude' is not null or undefined
            assertParamExists('searchAthletes', 'locationLongitude', locationLongitude)
            const localVarPath = `/api/athlete/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (locationLatitude !== undefined) {
                localVarQueryParameter['location.latitude'] = locationLatitude;
            }

            if (locationLongitude !== undefined) {
                localVarQueryParameter['location.longitude'] = locationLongitude;
            }

            if (sportId !== undefined) {
                localVarQueryParameter['sportId'] = sportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AthleteControllerApi - functional programming interface
 * @export
 */
export const AthleteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AthleteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async athleteMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.athleteMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.athleteMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAthletes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Athlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAthletes(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.getAllAthletes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} locationLatitude 
         * @param {number} locationLongitude 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [sportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAthletes(locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, sportId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AthleteSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAthletes(locationLatitude, locationLongitude, page, size, sort, sportId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AthleteControllerApi.searchAthletes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AthleteControllerApi - factory interface
 * @export
 */
export const AthleteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AthleteControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        athleteMe(options?: any): AxiosPromise<Athlete> {
            return localVarFp.athleteMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAthletes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Athlete>> {
            return localVarFp.getAllAthletes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationLatitude 
         * @param {number} locationLongitude 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [sportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAthletes(locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, sportId?: number, options?: any): AxiosPromise<Array<AthleteSummary>> {
            return localVarFp.searchAthletes(locationLatitude, locationLongitude, page, size, sort, sportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AthleteControllerApi - object-oriented interface
 * @export
 * @class AthleteControllerApi
 * @extends {BaseAPI}
 */
export class AthleteControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public athleteMe(options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).athleteMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public getAllAthletes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).getAllAthletes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationLatitude 
     * @param {number} locationLongitude 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {number} [sportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteControllerApi
     */
    public searchAthletes(locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, sportId?: number, options?: RawAxiosRequestConfig) {
        return AthleteControllerApiFp(this.configuration).searchAthletes(locationLatitude, locationLongitude, page, size, sort, sportId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AvatarControllerApi - axios parameter creator
 * @export
 */
export const AvatarControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAvatarCategoryEnum} category 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatar: async (category: GetAvatarCategoryEnum, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('getAvatar', 'category', category)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvatar', 'id', id)
            const localVarPath = `/api/avatar/{category}/{id}`
                .replace(`{${"category"}}`, encodeURIComponent(String(category)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvatarControllerApi - functional programming interface
 * @export
 */
export const AvatarControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvatarControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetAvatarCategoryEnum} category 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatar(category: GetAvatarCategoryEnum, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatar(category, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AvatarControllerApi.getAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AvatarControllerApi - factory interface
 * @export
 */
export const AvatarControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvatarControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetAvatarCategoryEnum} category 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatar(category: GetAvatarCategoryEnum, id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAvatar(category, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvatarControllerApi - object-oriented interface
 * @export
 * @class AvatarControllerApi
 * @extends {BaseAPI}
 */
export class AvatarControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetAvatarCategoryEnum} category 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvatarControllerApi
     */
    public getAvatar(category: GetAvatarCategoryEnum, id: number, options?: RawAxiosRequestConfig) {
        return AvatarControllerApiFp(this.configuration).getAvatar(category, id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAvatarCategoryEnum = {
    Account: 'ACCOUNT',
    Event: 'EVENT'
} as const;
export type GetAvatarCategoryEnum = typeof GetAvatarCategoryEnum[keyof typeof GetAvatarCategoryEnum];


/**
 * EventAdminControllerApi - axios parameter creator
 * @export
 */
export const EventAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {AdminActivateEventModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActivateEvent: async (eventId: number, moderationStatus: AdminActivateEventModerationStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminActivateEvent', 'eventId', eventId)
            // verify required parameter 'moderationStatus' is not null or undefined
            assertParamExists('adminActivateEvent', 'moderationStatus', moderationStatus)
            const localVarPath = `/api/admin/event/{eventId}/status/{moderationStatus}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"moderationStatus"}}`, encodeURIComponent(String(moderationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminDeleteEvent', 'eventId', eventId)
            const localVarPath = `/api/admin/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllEvents: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminGetEvent', 'eventId', eventId)
            const localVarPath = `/api/admin/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventAdminControllerApi - functional programming interface
 * @export
 */
export const EventAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {AdminActivateEventModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminActivateEvent(eventId: number, moderationStatus: AdminActivateEventModerationStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminActivateEvent(eventId, moderationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminActivateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminDeleteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetAllEvents(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Event>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetAllEvents(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminGetAllEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventAdminControllerApi.adminGetEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventAdminControllerApi - factory interface
 * @export
 */
export const EventAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {AdminActivateEventModerationStatusEnum} moderationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminActivateEvent(eventId: number, moderationStatus: AdminActivateEventModerationStatusEnum, options?: any): AxiosPromise<void> {
            return localVarFp.adminActivateEvent(eventId, moderationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteEvent(eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.adminDeleteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetAllEvents(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Event>> {
            return localVarFp.adminGetAllEvents(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetEvent(eventId: number, options?: any): AxiosPromise<Event> {
            return localVarFp.adminGetEvent(eventId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventAdminControllerApi - object-oriented interface
 * @export
 * @class EventAdminControllerApi
 * @extends {BaseAPI}
 */
export class EventAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} eventId 
     * @param {AdminActivateEventModerationStatusEnum} moderationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminActivateEvent(eventId: number, moderationStatus: AdminActivateEventModerationStatusEnum, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminActivateEvent(eventId, moderationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminDeleteEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminDeleteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminGetAllEvents(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminGetAllEvents(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventAdminControllerApi
     */
    public adminGetEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventAdminControllerApiFp(this.configuration).adminGetEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AdminActivateEventModerationStatusEnum = {
    Open: 'OPEN',
    Success: 'SUCCESS',
    Failed: 'FAILED'
} as const;
export type AdminActivateEventModerationStatusEnum = typeof AdminActivateEventModerationStatusEnum[keyof typeof AdminActivateEventModerationStatusEnum];


/**
 * EventControllerApi - axios parameter creator
 * @export
 */
export const EventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: async (createOrUpdateEventRequest: CreateOrUpdateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateEventRequest' is not null or undefined
            assertParamExists('createEvent', 'createOrUpdateEventRequest', createOrUpdateEventRequest)
            const localVarPath = `/api/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalEvent: async (createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateExternalEventRequest' is not null or undefined
            assertParamExists('createExternalEvent', 'createOrUpdateExternalEventRequest', createOrUpdateExternalEventRequest)
            const localVarPath = `/api/event/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateExternalEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteEvent', 'eventId', eventId)
            const localVarPath = `/api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (eventId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEvent', 'eventId', eventId)
            const localVarPath = `/api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationLatitude 
         * @param {number} locationLongitude 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [sportId] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents: async (locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, name?: string, sportId?: number, date?: string, distance?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationLatitude' is not null or undefined
            assertParamExists('searchEvents', 'locationLatitude', locationLatitude)
            // verify required parameter 'locationLongitude' is not null or undefined
            assertParamExists('searchEvents', 'locationLongitude', locationLongitude)
            const localVarPath = `/api/event/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (locationLatitude !== undefined) {
                localVarQueryParameter['location.latitude'] = locationLatitude;
            }

            if (locationLongitude !== undefined) {
                localVarQueryParameter['location.longitude'] = locationLongitude;
            }

            if (sportId !== undefined) {
                localVarQueryParameter['sportId'] = sportId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateEvent', 'eventId', eventId)
            // verify required parameter 'createOrUpdateEventRequest' is not null or undefined
            assertParamExists('updateEvent', 'createOrUpdateEventRequest', createOrUpdateEventRequest)
            const localVarPath = `/api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalEvent: async (eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateExternalEvent', 'eventId', eventId)
            // verify required parameter 'createOrUpdateExternalEventRequest' is not null or undefined
            assertParamExists('updateExternalEvent', 'createOrUpdateExternalEventRequest', createOrUpdateExternalEventRequest)
            const localVarPath = `/api/event/external/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateExternalEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventControllerApi - functional programming interface
 * @export
 */
export const EventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvent(createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createOrUpdateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.createEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExternalEvent(createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExternalEvent(createOrUpdateExternalEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.createExternalEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.deleteEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(eventId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.getEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} locationLatitude 
         * @param {number} locationLongitude 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [sportId] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEvents(locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, name?: string, sportId?: number, date?: string, distance?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvents(locationLatitude, locationLongitude, page, size, sort, name, sportId, date, distance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.searchEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(eventId, createOrUpdateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.updateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalEvent(eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalEvent(eventId, createOrUpdateExternalEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventControllerApi.updateExternalEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventControllerApi - factory interface
 * @export
 */
export const EventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: any): AxiosPromise<EventDto> {
            return localVarFp.createEvent(createOrUpdateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalEvent(createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: any): AxiosPromise<EventDto> {
            return localVarFp.createExternalEvent(createOrUpdateExternalEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(eventId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(eventId: number, options?: any): AxiosPromise<EventDto> {
            return localVarFp.getEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationLatitude 
         * @param {number} locationLongitude 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [name] 
         * @param {number} [sportId] 
         * @param {string} [date] 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents(locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, name?: string, sportId?: number, date?: string, distance?: number, options?: any): AxiosPromise<Array<EventSummary>> {
            return localVarFp.searchEvents(locationLatitude, locationLongitude, page, size, sort, name, sportId, date, distance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: any): AxiosPromise<EventDto> {
            return localVarFp.updateEvent(eventId, createOrUpdateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalEvent(eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: any): AxiosPromise<EventDto> {
            return localVarFp.updateExternalEvent(eventId, createOrUpdateExternalEventRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventControllerApi - object-oriented interface
 * @export
 * @class EventControllerApi
 * @extends {BaseAPI}
 */
export class EventControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public createEvent(createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).createEvent(createOrUpdateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public createExternalEvent(createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).createExternalEvent(createOrUpdateExternalEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public deleteEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).deleteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public getEvent(eventId: number, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).getEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} locationLatitude 
     * @param {number} locationLongitude 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [name] 
     * @param {number} [sportId] 
     * @param {string} [date] 
     * @param {number} [distance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public searchEvents(locationLatitude: number, locationLongitude: number, page?: number, size?: number, sort?: Array<string>, name?: string, sportId?: number, date?: string, distance?: number, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).searchEvents(locationLatitude, locationLongitude, page, size, sort, name, sportId, date, distance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {CreateOrUpdateEventRequest} createOrUpdateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public updateEvent(eventId: number, createOrUpdateEventRequest: CreateOrUpdateEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).updateEvent(eventId, createOrUpdateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {CreateOrUpdateExternalEventRequest} createOrUpdateExternalEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventControllerApi
     */
    public updateExternalEvent(eventId: number, createOrUpdateExternalEventRequest: CreateOrUpdateExternalEventRequest, options?: RawAxiosRequestConfig) {
        return EventControllerApiFp(this.configuration).updateExternalEvent(eventId, createOrUpdateExternalEventRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationControllerApi - axios parameter creator
 * @export
 */
export const OrganizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organization/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationControllerApi - functional programming interface
 * @export
 */
export const OrganizationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrganizations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrganizations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationControllerApi.getAllOrganizations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationControllerApi.organizationMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationControllerApi - factory interface
 * @export
 */
export const OrganizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.getAllOrganizations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationMe(options?: any): AxiosPromise<Organization> {
            return localVarFp.organizationMe(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationControllerApi - object-oriented interface
 * @export
 * @class OrganizationControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getAllOrganizations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return OrganizationControllerApiFp(this.configuration).getAllOrganizations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public organizationMe(options?: RawAxiosRequestConfig) {
        return OrganizationControllerApiFp(this.configuration).organizationMe(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SportControllerApi - axios parameter creator
 * @export
 */
export const SportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSports: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication LetssportServerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SportControllerApi - functional programming interface
 * @export
 */
export const SportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSports(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSports(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SportControllerApi.getAllSports']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SportControllerApi - factory interface
 * @export
 */
export const SportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSports(options?: any): AxiosPromise<Array<Sport>> {
            return localVarFp.getAllSports(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SportControllerApi - object-oriented interface
 * @export
 * @class SportControllerApi
 * @extends {BaseAPI}
 */
export class SportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SportControllerApi
     */
    public getAllSports(options?: RawAxiosRequestConfig) {
        return SportControllerApiFp(this.configuration).getAllSports(options).then((request) => request(this.axios, this.basePath));
    }
}



