import { SPORT_TYPE } from "@/types/sports";
import { Card } from "@/ui-kit/card";
import { SPORT_ICON_MAP } from "@/utils/icons";
import { cn } from "@/utils/tailwind";
import { Image } from "@/icons/image";
import { FC } from "react";
import { LocationIcon } from "@/icons/location";
import { Button } from "@/ui-kit/button";
import { Link } from "react-router-dom";
import { LikeIcon } from "@/icons/like";

type EventCardProps = {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  location: string;
  sports: string[];
  buttonText: string;
  className?: string;
  onClickHandler?: () => void;
  onLikeHandler?: () => void;
};

export const AthleteCard: FC<EventCardProps> = ({
  id,
  avatar,
  firstName,
  lastName,
  location,
  sports,
  buttonText,
  className,
  onLikeHandler,
}) => {
  const sportIcons = sports.map(
    (sport) => SPORT_ICON_MAP[sport as SPORT_TYPE] || null,
  ).filter((Icon) => Icon);
  
  return (
    <Card
      className={cn(
        "min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl overflow-hidden group",
        className,
      )}
    >
      <div className="relative flex-1">
        <Image src={avatar} alt="Event Card" />
        {sportIcons.map((Icon) => (
          <Icon className="absolute top-2 right-2" />
        ))}
        <div className='absolute bottom-0'>
          <section className="z-10 flex flex-col items-center flex-1 p-4 -mt-20 transition-colors bg-gradient-to-b from-transparent to-black from-5% to-36% from-36% to-58% to-72% group-hover:bg-gradient-to-t group-hover:gradient-blue-stops-card">
            <h6 className="h6 text-white mt-12 flex flex-col text-center">
                <span>{firstName}</span>
                <span>{lastName}</span>
                </h6>
            <p className="ft1 flex mt-2 text-white">
              <LocationIcon />
              <span className="ml-1">{location}</span>
            </p>
            <div className="mt-auto px-4 gap-2 flex items-center justify-between">
              <Button className="mt-4" asChild>
                <Link to={`/athletes/${id}`}>{buttonText}</Link>
              </Button>
              <Button
                className="mt-4 hover:bg-transparent"
                variant="ghost"
                size="icon"
                onClick={onLikeHandler}
              >
                <LikeIcon className="hover:fill-white transition-colors text-white" />
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Card>
  );
};
